<template>
  <!-- Footer-->
  <footer>
    <div class="container">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="col-lg-4 align-self-center">
          <div class="left-content">
            <!-- <div class="logo-with-text"> -->
            <img
              src="@/assets/images/s2s_logo.png"
              alt="Logo and Text"
              height="200"
              class="img"
            />
            <!-- </div> -->
            <!-- <div class="my-5">
              <button class="btn rounded-pill px-5 btn-jungle-green">
                Register Here
              </button>
            </div> -->
          </div>
        </div>
        <div class="col-lg-5 align-self-center position-relative">
          <div class="right-content">
            <!-- <div class="logo-with-text"> -->
            <img
              src="@/assets/images/new-design/tagline-img.png"
              alt="unli data"
              class="img"
            />
            <!-- </div> -->
            <!-- <div class="my-5">
              <button class="btn rounded-pill px-5 btn-jungle-green">
                Register Here
              </button>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="row h-100">
        <div class="col-lg-5 align-self-start">
          <div class="pb-3">
            <h4 class="fw-bold">S2S INTERNET</h4>
            <img
              src="@/assets/images/s2s_logo.png"
              height="120"
              alt="s2slogo"
            />
          </div>
        </div>

        <div class="col-lg-4"></div>
      </div> -->
      <!-- <hr class="gray mt-5" />
      <div class="row h-100">
        <div class="col-lg-6">
          <div class="align-self-center">
            <small class="text-muted">© S2S Internet</small>
          </div>
        </div>
        <div class="col-lg-6 align-self-center col-footer">
          <ul class="footer-menu">
            <li class="footer-menu-item"><a href="#">Privacy Policy</a></li>
            <li class="footer-menu-item">
              <a href="#">Terms and Conditions</a>
            </li>
            <li class="footer-menu-item"><a href="#">Cookie Policy</a></li>
          </ul>
        </div>
      </div> -->
    </div>
    <div class="bottom-content">surf2sawa@{{ year }}</div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: "",
    };
  },
  mounted() {
    const d = new Date();
    this.year = d.getFullYear();
  },
};
</script>
