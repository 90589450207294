<template>
  <div>
    <Navbar />
    <div class="page-loader" :class="{ hidden: getLoadedStatus }">
      <div class="spinner">
        <img
          src="@/assets/images/s2s_logo.png"
          alt="s2s"
          class="imageLoader"
          height="80"
        />
      </div>
      <div class="txt">Loading</div>
    </div>

    <button @click="topFunction" id="myBtn" title="Go to top">Top</button>

    <!-- Masthead-->
    <main-section></main-section>

    <!-- about section -->
    <about-section></about-section>

    <!-- Our data plans-->
    <data-plan-section></data-plan-section>

    <!-- no limits-->
    <section class="page-section where-to-find">
      <div class="container" data-aos="fade-up" data-aos-duration="2000">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="text-center">
              <h2 class="text-white">Saan mahahanap ang Surf2Sawa?</h2>
              <div class="horizontal-divider"></div>
            </div>
          </div>

          <div class="col-lg-9 py-5">
            <div class="text-center">
              <p class="text-white">
                Stay updated sa ating mga Social Media para malaman kung mayroon
                nang nakainstall na Surf2Sawa Zone sa inyong barangay!
              </p>
            </div>
          </div>

          <div class="col-lg-9 py-5">
            <div class="text-center">
              <button class="btn-fb me-4" @click="goToFb"></button>
              <button class="btn-ig me-4" @click="goToIg"></button>
              <button class="btn-tiktok me-4" @click="goToTiktok"></button>
              <button class="btn-yt" @click="goToYt"></button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section other-page" id="become-retailer">
      <div class="container" data-aos="fade-up" data-aos-duration="2000">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="text-center">
              <h2>Dagdag kita with Surf2Sawa!</h2>
              <div class="horizontal-divider"></div>
            </div>
          </div>

          <div class="col-lg-9 py-5">
            <div class="text-center">
              <p>Naghahanap kami ng mga retailer ng Surf2Sawa load!</p>
              <p>
                Kung mayroon kayong sari-sari store, laundromat, o sariling
                negosyo pwede kayo magbenta ng Surf2Sawa!
              </p>
              <div class="my-5">
                <button class="btn rounded-pill px-5 btn-jungle-green">
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section gifting" id="topup">
      <div class="container" data-aos="fade-up" data-aos-duration="2000">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="text-center">
              <h2>
                Top-up your S2S account here!
                <!-- <span class="gift-text">Gift!</span> -->
              </h2>
              <div class="horizontal-divider"></div>
            </div>
          </div>
          <div class="col-lg-9 py-5">
            <div class="text-center">
              <div class="py-3">
                <img
                  src="../assets/images/girl-sitting.png"
                  height="100"
                  alt="girl-image"
                />
              </div>
              <p>Load your account for as low as <b>₱ 200</b>!</p>
              <div class="my-5" v-if="!getMaintenance && buyLoadIsAvailable">
                <router-link
                  to="/topup"
                  class="btn rounded-pill px-5 btn-jungle-green"
                  >Continue</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section style="background-color: #ffb636" id="surftime-page">
      <Surftime></Surftime>
    </section>

    <!-- apply section -->
    <apply-section></apply-section>

    <section
      class="page-section blast-tv-section"
      id="blasttv"
      @click.prevent="gotoBlast"
    >
      <div class="container" data-aos="fade-up" data-aos-duration="2000">
        <!-- <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="row justify-content-end">
              <div class="col-lg-6">
                <div class="text-center">
                  <div class="offer-image1"></div>
                  <div class="offer-plus"></div>
                  <div class="offer-image2"></div>
                  <div class="my-5">
                    <a
                      href="http://apply.surf2sawa.com/"
                      class="btn rounded-pill px-5 btn-jungle-green"
                      >Apply Now</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </section>

    <section class="page-section contact" id="contact-us">
      <div class="container" data-aos="fade-up" data-aos-duration="2000">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <h2>Send us a message</h2>
            <div class="horizontal-divider-thin"></div>
          </div>
          <div class="col-lg-5">
            <form ref="contactForm" @submit.prevent="onSubmit()">
              <div class="contact-form">
                <div class="row mb-3 align-items-center">
                  <div class="col-lg-6 col-sm-12">
                    <label for="firstname" class="col-form-label"
                      >First name</label
                    >
                    <input
                      type="text"
                      v-model="raw.firstname"
                      class="form-control rounded-pill"
                      placeholder="First name"
                      required
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <label for="lastname" class="col-form-label"
                      >Last name</label
                    >
                    <input
                      type="text"
                      v-model="raw.lastname"
                      class="form-control rounded-pill"
                      placeholder="Last name"
                      required
                    />
                  </div>
                </div>

                <div class="row mb-3 align-items-center">
                  <div class="col-lg-6 col-sm-12">
                    <label for="emailaddress" class="col-form-label"
                      >Email Address</label
                    >
                    <input
                      type="email"
                      v-model="form.customerEmailAddress"
                      class="form-control rounded-pill"
                      placeholder="juandelacruz@gmail.com"
                      required
                    />
                  </div>

                  <div class="col-lg-6 col-sm-12">
                    <label for="mobileNumber" class="col-form-label"
                      >Mobile Number</label
                    >
                    <input
                      v-mask="'+63 ### #### ###'"
                      masked="true"
                      type="tel"
                      v-model="raw.mobileNumber"
                      class="form-control rounded-pill"
                      placeholder="+63 915 XXXX XXX"
                      required
                    />
                  </div>
                </div>

                <div class="row mb-3 align-items-center">
                  <div class="col-12">
                    <label for="message" class="col-form-label">Message</label>
                    <textarea
                      class="form-control textarea-message"
                      rows="5"
                      v-model="form.description"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                </div>

                <div class="row mb-3 align-items-center">
                  <div class="col-12">
                    <button
                      type="submit"
                      :disabled="loadingBtn"
                      class="btn btn-jungle-green rounded-pill px-5"
                    >
                      <div
                        v-if="loadingBtn"
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-lg-4 px-5 pt-5">
            <div class="text-center">
              <div class="contact-details">
                <div class="text-uppercase contact-us-label">Contact us</div>
                <ul class="social-media">
                  <li class="social-media-item">
                    <a href="javascript: void(0)" @click="goToFb()"
                      ><i class="icon facebook"></i
                    ></a>
                    <div class="social-text">facebook.com/Surf2Sawa</div>
                  </li>
                  <li class="social-media-item">
                    <a href="#"><i class="icon messenger"></i></a>
                    <div class="social-text">S2S Internet</div>
                  </li>
                  <li class="social-media-item">
                    <a href="javascript: void(0)" @click="goToIg()"
                      ><i class="icon ig"></i
                    ></a>
                    <div class="social-text">S2S Internet</div>
                  </li>
                  <li class="social-media-item">
                    <a href="#"><i class="icon email"></i></a>
                    <div class="social-text">contactus@s2sinternet.com</div>
                  </li>
                  <li class="social-media-item">
                    <a href="#"><i class="icon address"></i></a>
                    <div class="social-text">
                      14/F Net Cube Center 3rd Ave corner 30th Street E-Square
                      Zone, Bonifacio Global City Taguig City, Metro Manila
                    </div>
                  </li>
                </ul>
              </div>
              <!-- <p>
                14/F Net Cube Centre 3rd Ave Corner 30th Street, E-Square Zone,
                Bonifacio Global City Taguig City, Metro Manila
              </p>
              <p>
                <a href="mailto:support@s2sinternet.co" class="text-white">
                  support@s2sinternet.com
                </a>
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Surftime from "./Surftime.vue";
import MainSection from "./sections/MainSection.vue";
import AboutSection from "./sections/AboutSection.vue";
import DataPlanSection from "./sections/DataPlanSection.vue";
import ApplySection from "./sections/ApplySection.vue";
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Sa halagang P1,000 makakabitan ka na ng Surf2Sawa Prepaid Fiber Internet! Pagka-install, maari nang pumili ng Surf2Sawa plan na para sa 'yo.",
      },
    ],
  },
  data() {
    return {
      loadingBtn: false,
      raw: {
        firstname: "",
        lastname: "",
        mobileNumber: "",
      },
      form: {
        channel: "W",
        category: "7",
        subCategory: "",
        customerType: 1,
        customerName: "",
        customerMobileNumber: "",
        customerEmailAddress: "",
        description: "",
      },
    };
  },
  components: {
    Navbar,
    Footer,
    Surftime,
    MainSection,
    AboutSection,
    DataPlanSection,
    ApplySection,
  },
  methods: {
    gotoBlast() {
      this.$router.push({ path: "blasttv" });
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    goToFb() {
      window.location = "https://facebook.com/Surf2Sawa";
    },
    goToIg() {
      window.location = "https://www.instagram.com/s2sinternet/";
    },

    goToTiktok() {
      window.location = "https://www.tiktok.com/@surf2sawa";
    },

    goToYt() {
      window.location = "#";
    },

    async onSubmit() {
      this.loadingBtn = true;
      let data = this.form;
      data.customerName = `${this.raw.firstname} ${this.raw.lastname}`;
      data.customerMobileNumber = this.trimMe(this.raw.mobileNumber);
      const date = new Date();
      const config = {
        headers: {
          accept: "application/json",
          "wsc-timestamp": date.getTime(),
        },
      };

      await this.$contact
        .post("/ticket-integration", data, config)
        .then((response) => {
          if (response.data.status) {
            this.loadingBtn = false;
            this.$refs.contactForm.reset();
            this.$swal(
              "Thank you!",
              "Your message has been successfully sent. We will contact you very soon!",
              "success"
            ).then(() => {
              // this.$router.push("/");
              this.resetForm();
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    resetForm() {
      this.form.customerMobileNumber = "";
      this.form.customerEmailAddress = "";
      this.form.customerEmailAddress = "";
      this.form.description = "";

      this.raw.firstname = "";
      this.raw.lastname = "";
      this.raw.mobileNumber = "";
    },

    trimMe(num) {
      return num.replace(/\s+/g, "").replace("+", "");
    },
  },
  created() {
    this.$store.dispatch("ADD_SKU", {});
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = () => {
      var mybutton = document.getElementById("myBtn");
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    };

    let params = window.location.search?.replace("?", "").split("=");

    let data = {};
    for (var x = 0; x < params.length; x++) {
      data[params[x]] = params[x + 1];
    }

    window.localStorage.setItem("source", data.source);

    // AOS.init();
  },

  computed: {
    getLoadedStatus() {
      return this.$store.getters.IS_LOADED;
    },

    buyLoadIsAvailable() {
      const app = this.$store.getters.GET_APPLICATION;
      return app?.settings?.enableBuyLoad;
      // return false;
    },

    getMaintenance() {
      const app = this.$store.getters.GET_APPLICATION;
      if (app?.maintenance) {
        return true;
      }

      return false;
    },
  },

  mounted() {
    window.addEventListener("load", () => {
      const loader = document.querySelector(".page-loader");
      loader.className += " hidden"; // class "loader hidden"
      this.$store.dispatch("ADD_IS_LOADED_STATUS", {
        isLoaded: true,
      });
    });
  },
};
</script>

<style scoped>
/* PRELOADER CSS */
.page-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #3b054f;
  z-index: 9999;
}

.page-loader > .txt {
  color: #feb538;
  text-align: center;
  top: 45%;
  position: relative;
  /* text-transform: uppercase; */
  letter-spacing: 0.3rem;
  /* font-weight: bold; */
  line-height: 1.5;
}

.spinner {
  position: relative;
  top: 40%;
  width: 130px;
  height: 80px;
  margin: 0 auto;
}

.page-loader.hidden {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
    moz-transform: rotateY(0deg);
    ms-transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
    moz-transform: rotateY(360deg);
    ms-transform: rotateY(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
  }
}
.imageLoader {
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  -webkit-animation-name: spin;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 5s;
}
</style>
