<template>
  <header class="masthead">
    <div class="container h-100" data-aos="fade-up" data-aos-duration="2000">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="col-lg-12 content">
          <div class="left-content">
            <div class="logo-with-text">
              <img
                src="@/assets/images/new-design/logo.png"
                alt="Logo and Text"
                class="img"
              />
            </div>
          </div>

          <div class="right-content">
            <!-- <div class="iframe-container">
              <div class="iframe-border"></div>
              <iframe
                class="responsive-iframe"
                src="https://www.youtube.com/embed/-JQ6V_ZBxAc?si=ctOOZXmZ8xwoN1Yy"
                allowfullscreen
              ></iframe>
            </div> -->
            <!-- <div class="iframe-container">
              <div class="iframe-border"></div>
              <iframe
                @click="handleClick"
                class="responsive-iframe"
                src="https://www.youtube.com/embed/-JQ6V_ZBxAc?si=ctOOZXmZ8xwoN1Yy"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
              
            </div> -->

            <div class="iframe-container">
              <iframe
                class="responsive-iframe"
                src="https://www.youtube.com/embed/-JQ6V_ZBxAc?si=ctOOZXmZ8xwoN1Yy"
                title="YouTube video player"
                frameborder="0"
                height="700"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
              <div class="text-price">
                <p>FOR AS LOW AS</p>
                <p><span>P4/DAY</span> PER USER</p>
              </div>
              <img
                src="@/assets/images/new-design/header-right-img.png"
                alt="right-image"
                height="400"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    handleClick() {
      console.log("click");
    },
  },
};
</script>
