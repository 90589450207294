<template>
  <iframe
    src="https://prepaidfiber.s2s.ph"
    width="100%"
    height="720"
    frameborder="0"
  ></iframe>
</template>

<script>
export default {};
</script>
