<template>
  <div>
    <div
      class="maintenance-container"
      v-if="getMaintenance || !buyLoadIsAvailable"
    >
      <span v-if="getMaintenance">Website is under maintenance.</span>
      <span class="ms-2" v-if="!buyLoadIsAvailable">
        Topup is not available.</span
      >
    </div>
    <nav
      class="navbar navbar-expand-lg fixed-top"
      :class="{
        'margin-top-25': getMaintenance || !buyLoadIsAvailable,
      }"
      id="mainNav"
    >
      <div class="container">
        <!-- <router-link class="navbar-brand js-scroll-trigger" to="/"
        >S2S INTERNET</router-link
      > -->
        <a class="navbar-brand js-scroll-trigger" href="#">
          <img
            src="@/assets/images/s2s_logo.png"
            class="s2slogo"
            alt="s2slogo"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click.prevent="toggleNav($event)"
        >
          <i class="bi bi-list"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item d-none d-lg-block">
              <a href="#apply" class="nav-link apply-btn js-scroll-trigger"
                >Apply</a
              >
            </li>
            <li class="nav-item d-none d-lg-block">
              <a href="#blasttv" class="nav-link js-scroll-trigger">
                <img
                  src="@/assets/images/blasttv/blasttv-logo.png"
                  height="18"
                  alt="blasttv"
                />
              </a>
            </li>
            <li class="nav-item">
              <!-- <router-link to="gifting" class="nav-link js-scroll-trigger"
              >Gifting</router-link -->
              <a href="#topup" class="nav-link js-scroll-trigger">TOP-UP</a>
            </li>
            <li class="nav-item">
              <a href="#surftime-page" class="nav-link js-scroll-trigger"
                >Surftime</a
              >
            </li>
            <li class="nav-item">
              <a href="#data-plans" class="nav-link js-scroll-trigger"
                >Data Plans</a
              >
            </li>
            <li class="nav-item">
              <a href="#become-retailer" class="nav-link js-scroll-trigger"
                >Become a Retailer</a
              >
            </li>
            <li class="nav-item">
              <a href="#contact-us" class="nav-link js-scroll-trigger"
                >Contact Us</a
              >
            </li>
            <li class="nav-item d-lg-none">
              <a href="#blasttv" class="nav-link js-scroll-trigger">
                <img
                  src="@/assets/images/blasttv/blasttv-logo.png"
                  height="18"
                  alt="blasttv"
                />
              </a>
            </li>
            <li class="nav-item d-lg-none">
              <a href="#apply" class="nav-link apply-btn js-scroll-trigger"
                >Apply</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navIsOpen: false,
    };
  },
  methods: {
    toggleNav() {
      this.navIsOpen = !this.navIsOpen;
      const header = document.querySelector("nav");
      if (!this.navIsOpen) {
        setTimeout(() => {
          header.classList.remove("navbar-scrolled");
        }, 250);
        return;
      }

      header.classList.add("navbar-scrolled");
    },
  },
  mounted() {
    let scrollpos = window.scrollY;
    const header = document.querySelector("nav");
    const header_height = header.offsetHeight;

    const add_class_on_scroll = () => header.classList.add("navbar-scrolled");
    const remove_class_on_scroll = () =>
      header.classList.remove("navbar-scrolled");

    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY;

      if (scrollpos >= header_height) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
      // console.log(scrollpos);
    });
  },

  computed: {
    buyLoadIsAvailable() {
      const app = this.$store.getters.GET_APPLICATION;
      return app?.settings?.enableBuyLoad;
      // return false;
    },

    getMaintenance() {
      const app = this.$store.getters.GET_APPLICATION;
      if (app?.maintenance) {
        return true;
      }

      return false;
    },
  },
};
</script>
